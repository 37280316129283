import React from "react";
import { graphql } from "gatsby";
import { ShopLayout } from "../components/ShopLayout";

const BespokePage = ({ data, pageContext }) => {
    const { edges } = data.allMarkdownRemark;
    const products = edges.map(({ node }) => ({
        id: node.id,
        title: node.frontmatter.title,
        image: node.frontmatter.featuredImage,
        slug: node.fields.slug
    }));
    return <ShopLayout products={products} pageContext={pageContext} page='bespoke-orders' title='Bespoke orders'/>;
};

export default BespokePage;

export const BespokePageQuery = graphql`
    query BespokePage($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "bespoke-product-page" } } }
            sort: { order: DESC, fields: [frontmatter___title] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        price
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
